import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import TradeshowsEventsImg from "../../components/images/promotional/tradeshows"

export default function TradeshowsEvents() {
    return (
        <>
            <Layout>
                <SEO title="Tradeshows & Events" />
                <Display
                    title="Tradeshows & Events"
                    description="Whether your conference, tradeshow or training is virtual or in person, make sure you have the backdrops, signage and swag to make it a success."
                    prev="/promotional/promo-items"
                    next="/promotional/apparel-bags"
                    image={<TradeshowsEventsImg />}
                />
            </Layout>                
        </>
    )
}
